
import { defineComponent } from "vue";
import { showCreateNewTeamModal } from "../Modals/ModalController";
import { Team } from "../../types";
import {
  updateStoreTeamsLocationsAndEvents,
  getTeamsLocationsAndEvents,
  shareLinkToTeam,
  linkToTeam,
  sendGtagEvent,
} from "../../common/common";
import QrcodeVue from "qrcode.vue";
export default defineComponent({
  components: {
    QrcodeVue,
  },
  data() {
    return { showQR: false };
  },
  beforeRouteEnter(to, from, next) {
    // set location to null if you're just getting into creating the event
    if (from?.name === "Dashboard") {
      next((vm) => {
        vm.$store.commit("setLocationContext", {
          nickname: null,
          placeLat: null,
          placeLong: null,
          address: null,
        });
      });
    } else {
      next();
    }
  },
  async mounted() {
    const e = await getTeamsLocationsAndEvents();
    await updateStoreTeamsLocationsAndEvents(e);
    if (this.teamId && this.teamId !== "create-new-team") {
      this.$emit("enable-next");
    }
  },
  methods: {
    async shareTeam() {
      sendGtagEvent(this.$gtag, "share_link_to_team", {
        eventLabel: "user_shared_link",
        event_category: "engagement",
        value: this.teamId,
      });
      await shareLinkToTeam(this.teamId, this.teamName);
    },
  },
  computed: {
    link() {
      return linkToTeam(this.teamName, this.teamId);
    },
    teams: {
      get() {
        return this.$store.state.userTeams;
      },
      set(value: Team[]) {
        this.$store.commit("setUserTeams", value);
      },
    },
    teamName: {
      get() {
        return this.$store.state.eventWizardContext.teamName;
      },
      set(value: string) {
        this.$store.dispatch("setEventWizardTeamNameContext", value);
      },
    },
    teamId: {
      get() {
        return this.$store.state.eventWizardContext.teamId;
      },
      set(value: string) {
        this.$store.dispatch("setEventWizardTeamIdContext", value);
      },
    },
  },
  watch: {
    teamId(newTeamId, oldLocations) {
      if (newTeamId === "create-new-team") {
        showCreateNewTeamModal();
        this.teamName = "";
        this.$emit("disable-next");
      } else if (newTeamId) {
        this.$emit("enable-next");
        this.teamName = this.teams.find(
          (t) => t.uuidPrimaryKey === newTeamId
        )?.teamName;
      } else {
        this.$emit("disable-next");
      }
    },
  },
});
